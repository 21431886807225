import React from "react";
import "./Footer.css";
import whiteLogo from "../../Images/Fleek White .png";
import { NavLink } from "react-router-dom";

const Footer = () => {


  return (
    <>
      <div className="footer-section">
        <div className="footer-container">
          <div className="row gx-0 gy-5 ps-2 pe-2">
            <div className="col-xlg-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 ">
              <div className="footer-logo-div">
                <img src={whiteLogo} alt="fleek-logo" />
                <p className="me-5">
                  A smart and professional solution for all SaaS, software and
                  tech companies digital agencies.
                </p>
                <div className="footer-logo-social-div">
                  <span>
                    <a href="https://www.facebook.com/fleekbd?mibextid=ZbWKwL">
                      <i className="fa-brands fa-facebook"></i>
                    </a>
                  </span>

                  <span>
                    <NavLink to="/">
                      <i className="fa-brands fa-linkedin"></i>
                    </NavLink>
                  </span>

                  <span>
                    <NavLink to="/">
                      <i className="fa-brands fa-square-x-twitter"></i>
                    </NavLink>
                  </span>

                  <span>
                    <NavLink to="/">
                      <i className="fa-brands fa-skype"></i>
                    </NavLink>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-xlg-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 ">
              <div className="footer-services-div">
                <h5 className="text-white">Services</h5>
                <p >
                  Website & Software Development
                </p>

                <p >Mobile App Development</p>

                <p >
                  API Integration & Development
                </p>

                <p >Software QA & Testing</p>

                <p >Script, Bot & Automation</p>

                <p >UI/UX Design & Development</p>

                <p >Graphics Design</p>

                <p >
                  Motion Graphics & Video Editing
                </p>
              </div>
            </div>

            <div className="col-xlg-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 ">
              <div className="footer-services-div">
                <h5 className="text-white">Company</h5>
                <p >About Us</p>

                <p>Our Team</p>

                <p >Careers</p>

                <p >Contact</p>

                <p >Projects</p>

                <p >Blog</p>

                <p >Privacy Policy</p>

                <p >FAQ</p>
              </div>
            </div>

            <div className="col-xlg-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="footer-address-div">
                <h5 className="text-white">USA Office</h5>
                <p>
                  <span>
                    <i className="fa-solid fa-location-dot"></i>
                  </span>{" "}
                  H-417, R- 7, Baridhara DOHS, Dhaka-1206
                </p>
                <p>
                  <span>
                    <i className="fa-solid fa-envelope"></i>
                  </span>{" "}
                  info@fleekbd.xyz
                </p>
                <p>
                  <span>
                    <i className="fa-solid fa-phone"></i>
                  </span>
                  +8801977016090
                </p>
              </div>

              <div className="footer-address-div">
                <h5 className="text-white mt-4">Bangladesh Office</h5>
                <p>
                  <span>
                    <i className="fa-solid fa-location-dot"></i>
                  </span>{" "}
                  H-417, R- 7, Baridhara DOHS, Dhaka-1206
                </p>
                <p>
                  <span>
                    <i className="fa-solid fa-envelope"></i>
                  </span>{" "}
                  info@fleekbd.xyz
                </p>
                <p>
                  <span>
                    <i className="fa-solid fa-phone"></i>
                  </span>
                  +8801977016090
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom-section">
        <p>
          Copy{" "}
          <span>
            <i className="fa-regular fa-copyright"></i>
          </span>{" "}
          2024, All Rights Reserved by Fleek Bangladesh.
        </p>
      </div>
    </>
  );
};

export default Footer;
