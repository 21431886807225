import React from 'react';
import "./MultipleComponents.css"
import CountUp from 'react-countup';


const Counter = () => {
    return (
        <>
            <div>
            <div className='row gx-5 gy-5'>
                <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12'>
<div className='counter-card'>
    <h6><i className="fa-solid fa-users"></i></h6>
    <h2><CountUp
                    delay={2}
                    end={5}
                    style={{ fontFamily: "'Cormorant Garamond', serif" }}
                  /> <span><i className="fa-solid fa-plus"></i></span></h2>
    <p>Years of Experience</p>
</div>
                </div>


                <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12'>
<div className='counter-card'>
    <h6><i className="fa-solid fa-earth-americas"></i></h6>
    <h2><CountUp
                    delay={2}
                    end={20}
                    style={{ fontFamily: "'Cormorant Garamond', serif" }}
                  />  <span><i className="fa-solid fa-plus"></i></span></h2>
    <p>Countries Served</p>
</div>
                </div>


                <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12'>
<div className='counter-card'>
    <h6><i className="fa-solid fa-code"></i></h6>
    <h2><CountUp
                    delay={2}
                    end={30}
                    style={{ fontFamily: "'Cormorant Garamond', serif" }}
                  />  <span><i className="fa-solid fa-plus"></i></span></h2>
    <p>Skilled Experts</p>
</div>
                </div>


                <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12'>
<div className='counter-card'>
    <h6><i className="fa-solid fa-circle-check"></i></h6>
    <h2><CountUp
                    delay={2}
                    end={150}
                    style={{ fontFamily: "'Cormorant Garamond', serif" }}
                  />  <span><i className="fa-solid fa-plus"></i></span></h2>
    <p>Finished Projects</p>
</div>
                </div>
            </div>
            </div>
        </>
    );
}

export default Counter;