import React from "react";
import "./ClientReview.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ClientReview = () => {
  // Silk Carousel
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    initialSlide: 0,
    // responsive: [
    //   {
    //     breakpoint: 1200,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       infinite: true,
    //       dots: true,
    //     },
    //   },
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       infinite: true,
    //       dots: true,
    //     },
    //   },
    //   {
    //     breakpoint: 700,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       initialSlide: 2,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  return (
    <>
      <div className="home-client-review-section">
        <div className="home-client-review-title-div">
          <h3>
            {" "}
            Responses from Our <span>Trusted Clients</span>
          </h3>
          <p>
            Find out what our clients are saying about their experiences with
            Fleek Bangladesh. Discover their impactful stories and successes,
            and see how our solutions have made a significant difference. Their
            feedback underscores our commitment to quality and innovation.
          </p>
        </div>

        <div className="home-client-review-body-div">
          <Slider {...settings}>
            <div className="testimonial-container">
              <div className="progress-bar"></div>
              <div className="fas fa-quote-left fa-quote"></div>
              <div className="fas fa-quote-right fa-quote"></div>
              <p className="testimonial">
                Fleek Bangladesh exceeded our expectations in every way. From
                the initial consultation to the final product, their team
                demonstrated exceptional expertise and professionalism. The
                software solution they developed for us is not only functional
                but also user-friendly and scalable. Their attention to detail
                and commitment to meeting our deadlines was impressive. We
                highly recommend Fleek Bangladesh for any software development
                needs.
              </p>
              <div className="user">
                <img
                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&fit=max&s=707b9c33066bf8808c934c8ab394dff6"
                  alt="user"
                  className="user-image"
                />
                <div className="user-details">
                  <h4 className="username">Miyah Myles</h4>
                  <p className="role">USA</p>
                </div>
              </div>
            </div>

            <div className="testimonial-container">
              <div className="progress-bar"></div>
              <div className="fas fa-quote-left fa-quote"></div>
              <div className="fas fa-quote-right fa-quote"></div>
              <p className="testimonial">
                Working with Fleek Bangladesh was a fantastic experience. Their
                developers are highly skilled and brought our complex project to
                life with remarkable efficiency. Communication was seamless, and
                they were always available to address any concerns we had. The
                end result was a high-quality product that perfectly aligns with
                our business goals. We look forward to collaborating with them
                again on future projects.
              </p>
              <div className="user">
                <img
                  src="https://randomuser.me/api/portraits/men/97.jpg"
                  alt="user"
                  className="user-image"
                />
                <div className="user-details">
                  <h4 className="username">June Cha</h4>
                  <p className="role">Canada</p>
                </div>
              </div>
            </div>

            <div className="testimonial-container">
              <div className="progress-bar"></div>
              <div className="fas fa-quote-left fa-quote"></div>
              <div className="fas fa-quote-right fa-quote"></div>
              <p className="testimonial">
                Fleek Bangladesh provided outstanding service throughout our
                software development project. Their team was proactive,
                responsive, and incredibly knowledgeable. They took the time to
                understand our requirements and delivered a solution that
                surpassed our expectations. The project was completed on time
                and within budget. Their commitment to quality and customer
                satisfaction is evident, and we are very pleased with the
                outcome.
              </p>
              <div className="user">
                <img
                  src="https://randomuser.me/api/portraits/men/43.jpg"
                  alt="user"
                  className="user-image"
                />
                <div className="user-details">
                  <h4 className="username">Renee Sims</h4>
                  <p className="role">Australia</p>
                </div>
              </div>
            </div>

            <div className="testimonial-container">
              <div className="progress-bar"></div>
              <div className="fas fa-quote-left fa-quote"></div>
              <div className="fas fa-quote-right fa-quote"></div>
              <p className="testimonial">
                We had the pleasure of working with Fleek Bangladesh on a recent
                project, and the experience was exceptional. The team’s
                technical skills and problem-solving abilities were top-notch.
                They offered valuable insights and suggestions that greatly
                enhanced the final product. Their dedication to client success
                and willingness to go the extra mile made them a standout
                partner. We wholeheartedly recommend Fleek Bangladesh for any
                software development needs.
              </p>
              <div className="user">
                <img
                  src="https://randomuser.me/api/portraits/women/44.jpg"
                  alt="user"
                  className="user-image"
                />
                <div className="user-details">
                  <h4 className="username">Sasha Ho</h4>
                  <p className="role">Japan</p>
                </div>
              </div>
            </div>

            <div className="testimonial-container">
              <div className="progress-bar"></div>
              <div className="fas fa-quote-left fa-quote"></div>
              <div className="fas fa-quote-right fa-quote"></div>
              <p className="testimonial">
                Fleek Bangladesh is a reliable and innovative software
                development partner. They demonstrated a deep understanding of
                our requirements and delivered a solution that was both
                effective and efficient. The project management was smooth, and
                their team kept us informed at every stage of development. The
                result was a polished, high-performing application that met all
                our objectives. We are very satisfied with their work and will
                definitely engage them for future projects.
              </p>
              <div className="user">
                <img
                  src="https://randomuser.me/api/portraits/women/65.jpg"
                  alt="user"
                  className="user-image"
                />
                <div className="user-details">
                  <h4 className="username">Veeti Seppanen</h4>
                  <p className="role">Germany</p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default ClientReview;