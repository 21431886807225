import html from "../Images/technologies/html.png";
import css from "../Images/technologies/css.png";
import bootstrap from "../Images/technologies/bootstrap.png";
import tailwindCss from "../Images/technologies/tailwindCss.png";
import materialUI from "../Images/technologies/materialUI.png";
import  JS from "../Images/technologies/JS.png";
import reactJS from "../Images/technologies/reactJS.png";
import nextJS from "../Images/technologies/nextJS.png";
import  vueJS from "../Images/technologies/vueJS.png";
import  nuxtJS from "../Images/technologies/nuxtJS.png";
import  redux from "../Images/technologies/redux.png";
import  restAPI from "../Images/technologies/restAPI.png";
import  fastAPI from "../Images/technologies/fastAPI.png";
import  mySQL from "../Images/technologies/mySQL.png";
import  sqLite from "../Images/technologies/SQLite.png";
import  postgreSQL from "../Images/technologies/postgresql.png";
import  graphQL from "../Images/technologies/graphQL.png";
import mongoDB from "../Images/technologies/mongoDB.png";
import  python from "../Images/technologies/python.png";
import  django from "../Images/technologies/django.png";
import flask from "../Images/technologies/flask.png";
import php from "../Images/technologies/php.png";
import laravel from "../Images/technologies/laravel.png";
import nodeJS from "../Images/technologies/nodeJS.png";
import expressJS from "../Images/technologies/expressJS.png";
import java from "../Images/technologies/java.png";
import kotlin from "../Images/technologies/kotlin.png";
import swift from "../Images/technologies/swift.png";
import flutter from "../Images/technologies/flutter.png";
import reactNative from "../Images/technologies/reactNative.png";
import postman from "../Images/technologies/postman.png";
import swagger from "../Images/technologies/swagger.png";
import selenium from "../Images/technologies/selenium.png";
import testNG from "../Images/technologies/testNG.png";
import jmeter from "../Images/technologies/jmeter.png";
import jenkins from "../Images/technologies/Jenkins.png";
import appium from "../Images/technologies/appium.png";
import jira from "../Images/technologies/jira.png";
import celery from "../Images/technologies/celery.png";
import llms from "../Images/technologies/llms.png";
import chatgpt from "../Images/technologies/chatgpt.png";
import openAI from "../Images/technologies/open-ai.png";
import AI from "../Images/technologies/AI.png";
import rabbitMQ from "../Images/technologies/rabbitmq.png";
import aws from "../Images/technologies/aws.png";
import vps from "../Images/technologies/vps.png";
import playStore from "../Images/technologies/google-play-store.png";
import docker from "../Images/technologies/docker.png";
import kubernetes from "../Images/technologies/kubernetes.png";
import github from "../Images/technologies/github.png";
import figma from "../Images/technologies/figma.png";
import adobeXd from "../Images/technologies/adobe-xd.png";
import AdobePhotoshop from "../Images/technologies/Adobe_Photoshop.png";
import AdobeAI from "../Images/technologies/adobeAI.png";
import AdobeAfterEffects from "../Images/technologies/Adobe_After_Effects.png";
import adobePremierePro from "../Images/technologies/adobe-premiere-pro.png";
import filmora from "../Images/technologies/filmora.png";
import wbsiteDevelopment from "../Images/Services/websiteDevelopment.jpg"
import softwareDevelopment from "../Images/Services/softwareDevelopment.jpg";
import mobileAppDevelopment from "../Images/Services/mobileAppDevelopment.jpg";
import softwareTesting from "../Images/Services/softwareTesting.jpg";
import automationBot from "../Images/Services/automationBot.jpg";
import UiUxDesign from "../Images/Services/UiUxDesign.jpg";
import graphicsDesign from "../Images/Services/graphicsDesign.png";
import videoEditing from "../Images/Services/videoEditing.jpeg";
import digitalMarketing from "../Images/Services/digitalMarketing.jpg";



    export const servicesData = [
        {
            id: 0,
            title: "Website & Software Development",
            description: "Expert website and software development to elevate your business, enhance user experience, and drive growth.",
            image: wbsiteDevelopment,
        },
        {
            id: 1,
            title: "Mobile App Development",
            description: "Custom mobile app development to boost engagement, streamline operations, and enhance user experience.",
            image: mobileAppDevelopment,
        },
        {
            id: 2,
            title: "API Integration & Development",
            description: "Seamless API integration and development to enhance connectivity, streamline processes, and boost efficiency.",
            image: softwareDevelopment,
        },
        {
            id: 3,
            title: "Software QA & Testing",
            description: "Comprehensive software QA and testing to ensure quality, performance, and reliability for your products.",
            image: softwareTesting,
        },
        {
            id: 4,
            title: "Script, Bot & Automation",
            description: "Custom scripts, bots, and automation solutions to streamline tasks, improve efficiency, and save time.",
            image: automationBot,
        },
        {
            id: 5,
            title: "UI/UX Design & Development",
            description: "Creative UI/UX design and development to enhance user engagement and create intuitive experiences.",
            image: UiUxDesign,
        },
        {
            id: 6,
            title: "Graphics Design",
            description: "Professional graphic design services to elevate your brand with stunning visuals and captivating content.",
            image: graphicsDesign,
        },
        
        {
            id: 7,
            title: "Motion Graphics & Video Editing",
            description: "Dynamic motion graphics and video editing to create engaging visual stories that captivate your audience.",
            image: videoEditing,
        },
        {
            id: 8,
            title: "Digital Marketing",
            description: "Comprehensive digital marketing strategies to boost your online presence, drive traffic, and increase sales.",
            image: digitalMarketing,
        }
    ]


    export const technologyImages = [
        {
            id: 0,
            category: "show-all",
            technology: [
                { id: 0,  icon: reactJS, alt: "technology_image"},
                { id: 1,  icon: nextJS, alt: "technology_image"},
                { id: 2,  icon: vueJS, alt: "technology_image"},
                { id: 3,  icon: redux, alt: "technology_image"},
                { id: 4,  icon: mySQL, alt: "technology_image"},
                { id: 5,  icon: restAPI, alt: "technology_image"},
                { id: 6,  icon: python, alt: "technology_image"},
                { id: 7,  icon: php, alt: "technology_image"},
                { id: 8,  icon: laravel, alt: "technology_image"},
                { id: 9,  icon: nodeJS, alt: "technology_image"},
                { id: 10,  icon: kotlin, alt: "technology_image"},
                { id: 11,  icon: swift, alt: "technology_image"},
                { id: 12,  icon: flutter, alt: "technology_image"},
                { id: 13,  icon: reactNative, alt: "technology_image"},
                { id: 14,  icon: postman, alt: "technology_image"},
                { id: 15,  icon: java, alt: "technology_image"},
                { id: 16,  icon: selenium, alt: "technology_image"},
                { id: 17,  icon: jmeter, alt: "technology_image"},
                { id: 18,  icon: appium, alt: "technology_image"},
                { id: 19,  icon: celery, alt: "technology_image"},
                { id: 20,  icon: llms, alt: "technology_image"},
                { id: 21,  icon: openAI, alt: "technology_image"},
                { id: 22,  icon: aws, alt: "technology_image"},
                { id: 23,  icon: vps, alt: "technology_image"},
                { id: 24,  icon: playStore, alt: "technology_image"},
                { id: 25,  icon: docker, alt: "technology_image"},
                { id: 26,  icon: figma, alt: "technology_image"},
                { id: 27,  icon: AdobePhotoshop, alt: "technology_image"},
                { id: 28,  icon: AdobeAI, alt: "technology_image"},
                { id: 29,  icon: AdobeAfterEffects, alt: "technology_image"}

            ]
        },
        
        {
            id: 1,
            category: "web-development",
            technology: [
                { id: 0,  icon: html, alt: "technology_image"},
                { id: 1,  icon: css, alt: "technology_image"},
                { id: 2,  icon: bootstrap, alt: "technology_image"},
                { id: 3,  icon: tailwindCss, alt: "technology_image"},
                { id: 4,  icon: materialUI, alt: "technology_image"},
                { id: 5,  icon: JS, alt: "technology_image"},
                { id: 6,  icon: reactJS, alt: "technology_image"},
                { id: 7,  icon: nextJS, alt: "technology_image"},
                { id: 8,  icon: vueJS, alt: "technology_image"},
                { id: 9,  icon: nuxtJS, alt: "technology_image"},
                { id: 10,  icon: redux, alt: "technology_image"},
                { id: 11,  icon: restAPI, alt: "technology_image"},
                { id: 12,  icon: fastAPI, alt: "technology_image"},
                { id: 13,  icon: mySQL, alt: "technology_image"},
                { id: 14,  icon: sqLite, alt: "technology_image"},
                { id: 15,  icon: postgreSQL, alt: "technology_image"},
                { id: 16,  icon: graphQL, alt: "technology_image"},
                { id: 17,  icon: mongoDB, alt: "technology_image"},
                { id: 18,  icon: python, alt: "technology_image"},
                { id: 19,  icon: django, alt: "technology_image"},
                { id: 20,  icon: flask, alt: "technology_image"},
                { id: 21,  icon: php, alt: "technology_image"},
                { id: 22,  icon: laravel, alt: "technology_image"},
                { id: 23,  icon: nodeJS, alt: "technology_image"},
                { id: 24,  icon: expressJS, alt: "technology_image"}
            ]
        },

        {
            id: 2,
            category: "mobile-development",
            technology: [
                { id: 0,  icon: java, alt: "technology_image"},
                { id: 1,  icon: kotlin, alt: "technology_image"},
                { id: 2,  icon: swift, alt: "technology_image"},
                { id: 3,  icon: flutter, alt: "technology_image"},
                { id: 4,  icon: reactNative, alt: "technology_image"},
                { id: 5,  icon: restAPI, alt: "technology_image"},
                { id: 6,  icon: fastAPI, alt: "technology_image"},
                { id: 7,  icon: mySQL, alt: "technology_image"},
                { id: 8,  icon: sqLite, alt: "technology_image"},
                { id: 9,  icon: postgreSQL, alt: "technology_image"},
                { id: 10,  icon: graphQL, alt: "technology_image"},
                { id: 11,  icon: mongoDB, alt: "technology_image"},
                { id: 12,  icon: python, alt: "technology_image"},
                { id: 13,  icon: django, alt: "technology_image"},
                { id: 14,  icon: flask, alt: "technology_image"},
                { id: 15,  icon: php, alt: "technology_image"},
                { id: 16,  icon: laravel, alt: "technology_image"},
                { id: 17,  icon: nodeJS, alt: "technology_image"},
                { id: 18,  icon: expressJS, alt: "technology_image"}
            ]
        },

        {
            id: 3,
            category: "api-development",
            technology: [
                { id: 0,  icon: restAPI, alt: "technology_image"},
                { id: 1,  icon: fastAPI, alt: "technology_image"},
                { id: 2,  icon: postman, alt: "technology_image"},
                { id: 3,  icon: swagger, alt: "technology_image"}
            ]
        },

        {
            id: 4,
            category: "SQA-testing",
            technology: [
                { id: 0,  icon: java, alt: "technology_image"},
                { id: 1,  icon: selenium, alt: "technology_image"},
                { id: 2,  icon: testNG, alt: "technology_image"},
                { id: 3,  icon: jmeter, alt: "technology_image"},
                { id: 4,  icon: jenkins, alt: "technology_image"},
                { id: 5,  icon: postman, alt: "technology_image"},
                { id: 6,  icon: appium, alt: "technology_image"},
                { id: 7,  icon: jira, alt: "technology_image"},
            ]
        },

        {
            id: 5,
            category: "automation",
            technology: [
                { id: 0,  icon: python, alt: "technology_image"},
                { id: 1,  icon: nodeJS, alt: "technology_image"},
                { id: 2,  icon: selenium, alt: "technology_image"},
                { id: 3,  icon: celery, alt: "technology_image"},
                { id: 4,  icon: llms, alt: "technology_image"},
                { id: 5,  icon: chatgpt, alt: "technology_image"},
                { id: 6,  icon: openAI, alt: "technology_image"},
                { id: 7,  icon: AI, alt: "technology_image"},
                { id: 8,  icon: rabbitMQ, alt: "technology_image"},
            ]
        },

        {
            id: 6,
            category: "deployment",
            technology: [
                { id: 0,  icon: aws, alt: "technology_image"},
                { id: 1,  icon: vps, alt: "technology_image"},
                { id: 2,  icon: playStore, alt: "technology_image"},
                { id: 3,  icon: docker, alt: "technology_image"},
                { id: 4,  icon: jenkins, alt: "technology_image"},
                { id: 5,  icon: kubernetes, alt: "technology_image"},
                { id: 6,  icon: github, alt: "technology_image"},
            ]
        },

        {
            id: 7,
            category: "ui-ux-design",
            technology: [
                { id: 0,  icon: figma, alt: "technology_image"},
                { id: 1,  icon: adobeXd, alt: "technology_image"},
                { id: 2,  icon: AdobePhotoshop, alt: "technology_image"},
                { id: 3,  icon: AdobeAI, alt: "technology_image"},
                { id: 4,  icon: AdobeAfterEffects, alt: "technology_image"}
            ]
        },


        {
            id: 8,
            category: "graphics-design",
            technology: [
                { id: 0,  icon: AdobePhotoshop, alt: "technology_image"},
                { id: 1,  icon: AdobeAI, alt: "technology_image"},
            ]
        },


        {
            id: 9,
            category: "motion-graphics",
            technology: [
                { id: 0,  icon: AdobePhotoshop, alt: "technology_image"},
                { id: 1,  icon: AdobeAI, alt: "technology_image"},
                { id: 2,  icon: AdobeAfterEffects, alt: "technology_image"},
                { id: 3,  icon: adobePremierePro, alt: "technology_image"},
            ]
        },


        {
            id: 10,
            category: "video-editing",
            technology: [
                { id: 0,  icon: AdobeAfterEffects, alt: "technology_image"},
                { id: 1,  icon: adobePremierePro, alt: "technology_image"},
                { id: 2,  icon: filmora, alt: "technology_image"},
            ]
        },
    ]
  