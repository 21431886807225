import React from "react";
import "./Contact.css";
import BangladeshOfficeMap from "./BangladeshOfficeMap";
import UsaOfficeMap from "./UsaOfficeMap";
// import useFunctions from "../../Hooks/useFunctions";

const Contact = () => {
//   const { handleAddContactChange, handleAddContact, handleHomePage } = useFunctions();
  return (
    <>
      {/* Page Hader */}
   

      <div className="contact-section">
        <div className="row gx-5 gy-5">
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
            <div className="contact-form-header">
              <h3>
                Get in touch <span>with us</span>
              </h3>
            </div>

            <div className="contact-form-input-div">
              <form >
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Your Full Name"
                    name="name"
                    // onChange={handleAddContactChange}
                  />
                  <label for="floatingInput">Your Full Name</label>
                </div>

                <div className="form-floating mb-4">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Email"
                    name="email"
                    // onChange={handleAddContactChange}
                  />
                  <label for="floatingInput">Your Email Address</label>
                </div>

                <div className="form-floating mb-5">
                  <textarea
                    className="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ height: "250px" }}
                    name="message"
                    // onChange={handleAddContactChange}
                  ></textarea>
                  <label for="floatingTextarea2">Message here</label>
                </div>

                <div className="contact-form-submit-btn">
                  <button type="button"> Get Started Now</button>
                </div>
              </form>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <div className="contact-form-header">
              <h3>
                Contact <span>Details</span>
              </h3>
            </div>

            <div className="contact-form-address-div">
              <div className="contact-form-address-details">
                <h4>Bangladesh Address</h4>
                <p>
                  <i className="fa-solid fa-location-dot"></i> H-417, R- 7,
                  Baridhara DOHS, Dhaka-1206
                </p>
                <p>
                  <i className="fa-solid fa-envelope"></i> info@fleek.com.bd
                </p>
                <p>
                  <i className="fa-solid fa-phone"></i> +880 1977016090
                </p>
                <p>
                  {" "}
                  <i className="fa-solid fa-clock"></i> Saturday to Thursday:
                  9:00 AM - 6:00 PM
                </p>
              </div>

              <div className="contact-form-address-details">
                <h4>USA Address</h4>
                <p>
                  <i className="fa-solid fa-location-dot"></i> H-417, R- 7,
                  Baridhara DOHS, Dhaka-1206
                </p>
                <p>
                  <i className="fa-solid fa-envelope"></i> info@fleek.com.bd
                </p>
                <p>
                  <i className="fa-solid fa-phone"></i> +880 1977016090
                </p>
                <p>
                  {" "}
                  <i className="fa-solid fa-clock"></i> Saturday to Thursday:
                  9:00 AM - 6:00 PM
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row gy-5 contact-google-map-div">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <BangladeshOfficeMap />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <UsaOfficeMap />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;