import React from 'react';
import "./HomeBlog.css";
import wbsiteDevelopment from "../../Images/Services/websiteDevelopment.jpg";

const HomeBlog = () => {


    return (
        <>
            <div className='home-blog-section'>
            <div className='home-blog-title-div'>
                    <h3> Current Blog <span>Highlights</span></h3>
                    <p>Uncover expert perspectives, practical tips, and compelling stories from a range of industries, featuring tech leaders, startups, and well-established companies.</p>
                </div>


                <div className='row gx-5 gy-5 home-blogs-div'>
                <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-12 col-xsm-12">
<div className="home-blogs-card">
<div className="home-blogs-card-inner">
<div className="home-blogs-card-box">
<div className="home-blogs-card-img-box">
<img src={wbsiteDevelopment} alt="blogs_image"/>

</div>
<div className="home-blogs-card-icon" >
<span>
<a>
 <i className="fa-solid fa-arrow-right"></i>
</a>
</span>
</div>
</div>
</div>
<div className="home-blogs-card-content">
<div className='home-blogs-card-content-top'>
    <p>Business</p>
    <p>7 min read</p>
</div>

<div className='home-blogs-card-content-bottom'>
    <p><i className="fa-regular fa-circle-user"></i> Omar Faruk </p>
</div>

<h3>Website & Software Development</h3>
<p>We deliver custom website and software development solutions
to drive business growth and innovation.</p>

</div>
</div>
</div>

<div className='custom-button-div'>
<button className="custom-common-btn btn-3" ><span>View All Blogs</span></button>
</div>
</div>
            </div>
        </>
    );
}

export default HomeBlog;