import React from "react";
import "./BusinessPartner.css";
import wise from "../../Images/business-partner/WiseBlue.png";
import quickDigital from "../../Images/business-partner/quick-digital.png";
import eduWise from "../../Images/business-partner/eduwise.png";
import quickDigitalBangle from "../../Images/business-partner/quick-digital-bangla.png";
import greenExpectation from "../../Images/business-partner/greenExpectation.png";
import growtoro from "../../Images/business-partner/growtoro.png";
import klever from "../../Images/business-partner/klever.png";
import uplaunched from "../../Images/business-partner/Uplaunched.png";
import vipal from "../../Images/business-partner/vipal.png";
import windowWarePro from "../../Images/business-partner/windowWarePro.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BusinessPartner = () => {
  // Silk Carousel
  var settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="business-partener-img-card-div">
        <Slider {...settings}>
          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={wise}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={quickDigital}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={eduWise}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={quickDigitalBangle}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={greenExpectation}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={growtoro}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={klever}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={uplaunched}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={vipal}
              alt="business_partener_logo"
            />
          </div>

          <div className="business-partener-img-card">
            <img
              className="business-partener-img"
              src={windowWarePro}
              alt="business_partener_logo"
            />
          </div>
        </Slider>
      </div>
    </>
  );
};

export default BusinessPartner;