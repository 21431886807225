import React, { useState } from "react";
import "./HomeServices.css";
import {servicesData} from "../../LocalData/LocalData"



const HomeServices = () => {

  const [visibleCards, setVisibleCards] = useState(3);

  const handleLoadMore = () => {
    setVisibleCards((prev) => prev + 3);
  };

  return (
    <>
      <div className="home-services-section">
        <div className="home-title-div">
          <h3>
            Discover <span>Our Services</span>
          </h3>
          <p>Our Talented Team Transforms Your Ideas into Action</p>
        </div>

        <div className="row gx-5 gy-5 home-service-div">
        {servicesData.slice(0, visibleCards).map((item, index) => (
              <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-12 col-xs-12" key={item.id}>
              <div className="home-services-card">
                <div className="home-services-card-inner">
                  <div className="home-services-card-box">
                    <div className="home-services-card-img-box">
                      <img src={item.image} alt="services_image" />
                    </div>
                    <div className="home-services-card-icon">
                      <span>
                        <a>
                          <i className="fa-solid fa-arrow-right"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="home-services-card-content">
                  <h3>{item.title} </h3>
                  <p>
                    {item.description}
                  </p>
                </div>
              </div>
            </div>
        ))}

          <div className="custom-button-div">
          {visibleCards < servicesData.length && (
          <button className="custom-common-btn btn-3" onClick={handleLoadMore}>
          <span>More Services</span>
        </button>
      )}

          
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeServices;
