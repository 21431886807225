import React from 'react';
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
    width: "100%",
    height: "400px",
  };
  
  const center = {
    lat: 23.8121432,
    lng: 90.4132102,
  };

const BangladeshOfficeMap = () => {
    return (
        <>
            <div >
            <LoadScript
                  googleMapsApiKey={process.env.GOOGLE_API_KEY}
                >
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={18}
                  >
                    <Marker position={center} />
                  </GoogleMap>
                </LoadScript>
            </div>
        </>
    );
}

export default BangladeshOfficeMap;