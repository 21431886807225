import React from 'react';
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";



const containerStyle = {
    width: "100%",
    height: "400px",
  };
  
  const center = {
    lat: 32.8139058,
    lng: -79.8899505,
  };

const UsaOfficeMap = () => {
    return (
        <>
            <div>
            <LoadScript
                  googleMapsApiKey={process.env.GOOGLE_API_KEY}
                >
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={18}
                  >
                    <Marker position={center} />
                  </GoogleMap>
                </LoadScript>
            </div>
    </>
    );
}

export default UsaOfficeMap;