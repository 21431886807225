import React, { useState } from "react";
import HomeBanner from "../../Components/MultipleComponents/HomeBanner";
import Counter from "../../Components/MultipleComponents/Counter";
import HomeServices from "../../Components/MultipleComponents/HomeServices";
import HomeTechnology from "../../Components/MultipleComponents/HomeTechnology";
import ClientReview from "../../Components/MultipleComponents/ClientReview";
import BusinessPartner from "../../Components/MultipleComponents/BusinessPartner";
import Contact from "../Contact/Contact";
import HomeContactFooter from "../../Components/MultipleComponents/HomeContactFooter";
import AboutUs from "../AboutUs/AboutUs";
import HomeBlog from "../../Components/MultipleComponents/HomeBlog";
import HomeProject from "../../Components/MultipleComponents/HomeProject";

const Home = () => {
  return (
    <>
      <HomeBanner />

      <div className="contact-section">
        <Counter />
      </div>

      <div id="about-us">
        <AboutUs />
      </div>

      <div id="services">
        <HomeServices />
      </div>

      <div className="home-technology-section">
        <HomeTechnology />
      </div>

      <div className="home-project-section" id="projects">
        <HomeProject />
      </div>

      <ClientReview />

      <div className="home-business-partner-section">
        <BusinessPartner />
      </div>

      <div style={{ marginTop: "8rem" }} id="blog">
        <HomeBlog />
      </div>

      {/* <div className="home-contact-footer-section home-contact-footer-section-custom">
        <HomeContactFooter />
      </div> */}

      <div id="contact-us">
        <Contact />
      </div>
    </>
  );
};
export default Home;
