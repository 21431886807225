import React from "react";
import "./HomeProject.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import softwareDevelopment from "../../Images/Services/softwareDevelopment.jpg";

const HomeProject = () => {
  // const {
  //   navigate,
  //   projectsData,
  //   projectData,
  //   handleInduvidualProject,
  //   convertToBreakLines
  // } = useFunctions();



    // Silk Carousel
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

  return (
    <>
      <div>
        <div className="home-project-title-div">
          <h3>
            {" "}
            Discover Our Advanced <span>Project Solutions</span>
          </h3>
          <p>
            Enter our realm of creativity, where each project reveals a unique
            journey. This isn't merely a display—it's a glimpse into the future
            we're shaping, one innovation at a time.
          </p>
        </div>

        <div className="row gx-5 gy-5 home-service-div">
        <div
              className="col-xlg-4 col-lg-4 col-md-6 col-sm-12 col-xsm-12"
           
            >
              <div className="home-services-card">
                <div className="home-services-card-inner">
                  <div className="home-services-card-box">
                    <div className="home-services-card-img-box">
                    <div className="home-services-card-img-box">
                    <img src={softwareDevelopment} alt="services_image" />
                  </div>
                    </div>
                    <div className="home-services-card-icon" >
                      <span
                       data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                      >
                        <a>
                          <i className="fa-solid fa-arrow-right"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="home-services-card-content">
                  <h3>Website & Software Development</h3>
                  <p>     We deliver custom website and software development solutions
                  to drive business growth and innovation.</p>
                </div>
              </div>
            </div>

          <div className="custom-button-div">
            <button
              className="custom-common-btn btn-3"
            
            >
              <span>View All Projects</span>
            </button>
          </div>
        </div>
      </div>


      
      {/* ===================== */}
      {/* Project Details Modal */}
      {/* ===================== */}

      {/* <div
        className="modal fade modal-xl"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content p-4">
            <div className="modal-header">
              <h1
                className="modal-title fs-5 project-details-information-title"
                id="exampleModalLabel"
              >
                {projectData.title}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="project-details-information-div">
                <p className="project-details-information-technology">
                  {projectData.technology}
                </p>

                <p className="project-details-information-description">
                  {convertToBreakLines(projectData.description)}
                </p>
              </div>

              {projectData.videoId === "" ? (
                <></>
              ) : (
                <div className="project-details-information-video">
                  <iframe
                    src={`https://drive.google.com/file/d/${projectData.videoId}/preview`}
                    width="100%"
                    height="440"
                    allow="autoplay"
                  ></iframe>
                </div>
              )}

<div className="project-details-information-images-div">
                <Slider {...settings}>
                  {projectData.imageData &&
                    projectData.imageData.map((item, index) => (
                      <div
                        className="project-details-information-images"
                        key={index + 1}
                      >
                        {item.image_file_ID === "" ? (
                          <></>
                        ) : (
                          <iframe
                            src={`https://drive.google.com/file/d/${item.image_file_ID}/preview`}
                            width="100%"
                            height="440px"
                            allow="fullscreen"
                          ></iframe>
                        )}
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default HomeProject;