import React from "react";
 import "./MultipleComponents.css"
 import BannerVideo from "../../Images/web.mp4"
import BannerDocument from "./BannerDocument";

const HomeBanner = () => {
  
    return (
        <>

<div className="video-wrapper ">
  <video className="background" autoPlay muted loop >
         <source src={BannerVideo} type="video/mp4"/>
  </video>
  <div className="overlay"></div>
  <div className="content">
    <BannerDocument/>
   
  </div>
</div>
        </>
    )
};
export default HomeBanner;