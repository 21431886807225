import React from 'react';
import "./AboutUs.css";


const AboutUs = () => {



    return (
        <>
        
        <div className='about-us-section'>
            
      

            <div className="about-us-we-are-div">
               <div className='row gx-5 gy-3'>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 align-self-center'>
    <div className='about-us-we-are-info-div'>
    <h4>Who We Are</h4>
    <p style={{textAlign:"justify"}}>Welcome to Fleek Bangladesh! We are a forward-thinking software development company committed to delivering innovative technology solutions that empower businesses to excel in the digital landscape. Established in 2024, we have quickly grown into a trusted partner for organizations across various industries, leveraging technology to solve complex problems and drive meaningful change.</p>
    <p style={{textAlign:"justify"}}>At Fleek Bangladesh, our mission is clear: to empower businesses through cutting-edge software solutions that enhance productivity, foster growth, and create lasting value. We understand the unique challenges companies face in today’s fast-paced environment, and we are dedicated to providing tailored solutions that not only meet but exceed expectations. Our aim is to bridge the gap between technology and business, ensuring that our clients are equipped to thrive in an ever-evolving marketplace.</p>
    </div>
</div>

<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 align-self-center'>
    <div className='about-us-we-are-info-div'>
    <img src="https://readwrite.com/wp-content/uploads/2023/02/Top-Software-Development-Companies-in-2023.jpg" alt="about_us_growth-image"/>
    </div>
</div>
               </div>
            </div>


            <div className="about-us-we-are-div">
               <div className='row gx-5 gy-3 flex-wrap-reverse'>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 align-self-center'>
    <div className='about-us-we-are-info-div'>
    <img src="https://media.istockphoto.com/id/636609180/photo/developers-at-work.jpg?s=612x612&w=0&k=20&c=Lx1zncUHcsd8s87E3LhCCsUEvLIsEWB5AvWyX8YzSoQ=" alt="about_us_growth-image"/>
    </div>
</div>


<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 align-self-center'>
    <div className='about-us-we-are-info-div'>
    <h4>What We Do</h4>
    <p style={{textAlign:"justify"}}>At Fleek Bangladesh, we pride ourselves on being more than just a software development company; we are your dedicated technology partner. Our commitment to understanding your unique challenges and delivering customized solutions allows us to foster long-lasting relationships with our clients.</p>
    <p style={{textAlign:"justify"}}>We believe that every business has the potential to thrive with the right technology solutions. Our skilled team is passionate about leveraging the latest advancements in software development, cloud computing, and digital marketing to create impactful solutions that align with your vision and goals.</p>
    <p style={{textAlign:"justify"}}>As we look to the future, our mission remains steadfast: to empower organizations through technology, innovation, and excellence. We invite you to join us on this journey of transformation and growth. Let us work together to turn your ideas into reality, optimize your operations, and enhance your customer experience through our innovative solutions.</p>
    </div>
</div>
               </div>
            </div>


            <div className='about-us-growth-div'>
<h3>Our <span>Journey & Growth</span></h3>
<p>We collaborate with renowned brands to address their product problems through innovative approaches, paving the way for your business success.</p>


<div>
<div className="growth-item">
  <div className="growth-image">
    <div>
      <span>
   1
      </span>
    </div>
  </div>
  <div class="growth-details">
    <div>
      <h1>Our Mission</h1>
      <p style={{textAlign:"justify"}}>At Fleek Bangladesh, our mission is to empower organizations by providing tailored software solutions that enhance efficiency, drive growth, and create lasting impact. We strive to bridge the gap between technology and business, ensuring our clients stay ahead in a rapidly evolving market.</p>
    </div>
  </div>
</div>

<div class="growth-item">
  <div class="growth-image">
    <div>
    
      <span>
   2
      </span>
    </div>
  </div>
  <div class="growth-details">
    <div>
      <h1>Our Vision</h1>
      <p style={{textAlign:"justify"}}>We envision a future where technology seamlessly integrates with daily operations, making businesses more agile and competitive. Our goal is to be a trusted partner for companies looking to leverage technology for transformative growth.</p>
    </div>
  </div>
</div>

<div class="growth-item">
  <div class="growth-image">
    <div>
    
      <span>
     3
      </span>
    </div>
  </div>
  <div class="growth-details">
    <div>
      <h1>Our Values</h1>
      <p style={{textAlign:"justify"}}>We believe in continuous improvement and embrace new ideas to foster creativity. We uphold the highest standards of honesty and transparency in all our dealings. Teamwork is at the core of our approach, ensuring we achieve the best results for our clients. We are committed to delivering high-quality solutions that exceed expectations.</p>
    </div>
  </div>
</div>


<div class="growth-item">
  <div class="growth-image">
    <div>
    
      <span>
     4
      </span>
    </div>
  </div>
  <div class="growth-details">
    <div>
      <h1>Our Team</h1>
      <p style={{textAlign:"justify"}}>Our team comprises skilled professionals from diverse backgrounds, including software engineers, designers, and project managers. Together, we bring a wealth of knowledge and experience to every project, ensuring we meet the unique needs of our clients.</p>
    </div>
  </div>
</div>
</div>

            </div>


        </div>
        </>
    );
}

export default AboutUs;